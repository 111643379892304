// src/components/Header.js
import React from "react";
import { Navbar, Nav, Button, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <Navbar bg="light" sticky="top" expand="lg" className="header">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="images/avadhi-logo5.jpeg"
            alt="Brand logo"
            className="img-d-inline-block align-top logo-img"
          />
          {/* AAVADHI.AI */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/page1">
              <NavDropdown title="Product" id="basic-nav-dropdown">
                <NavDropdown.Item href="/traffic-enf">
                  <Nav.Link as={NavLink} to="/traffic-enf" end>
                    Traffic Enforcement
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="/space-monit">
                  <Nav.Link as={NavLink} to="/space-monit" end>
                    Public Space Monitoring
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="/retail">
                  <Nav.Link as={NavLink} to="/retail" end>
                    Retail
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/annot">
                  <Nav.Link as={NavLink} to="/annot" end>
                    Annotations
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </LinkContainer>
            {/* <LinkContainer to="/pages">
              <Nav.Link>Solutions</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
          </Nav>
          {/* <Nav className="ms-auto gap-2">
            <Button variant="outline-primary">Login</Button>
            <Button variant="primary">Get a Demo</Button>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
