// src/App.js
import Container from "react-bootstrap/Container";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Retail from "./pages/Retail";
import SpaceMonit from "./pages/SpaceMonit";
import Annot from "./pages/Annot";
import TrafficEnf from "./pages/TrafficEnf";
import About from "./pages/About";

function App() {
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/retail" element={<Retail />} />
          <Route exact path="/space-monit" element={<SpaceMonit />} />
          <Route exact path="/annot" element={<Annot />} />
          <Route exact path="/traffic-enf" element={<TrafficEnf />} />
          <Route exact path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Container>
  );
}

export default App;
