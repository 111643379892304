import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const VideoEmbed = ({ videoId, header }) => {
  return (
    <Container fluid className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10}>
          <h2 className="section-header">{header}</h2>
          <div className="video-wrapper">
            <iframe
              width="100%"
              height="500px"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoEmbed;
