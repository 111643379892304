// src/components/Annot.js
import React from "react";
import { Container, Row, Col, Stack, Image } from "react-bootstrap";

const Annot = () => {
  return (
    <Container className="text-center my-5">
      <h1 className="poppins-light">Aavadhi in Annotations for your models</h1>

      <Stack gap={3}>
        <Container className="fluid sm">
          Aavadhi enables automation in both Data Gathering and Data Annotation.
          Aavadhi leverages its in-house proprietary technologies as well as
          commercially available technologies for data-set gathering and
          data-labelling which the customers can leverage to enjoy savings in
          time and effort of up to 40-50%.
        </Container>
        <Container className="justify-content-center">
          <Row>
            <Col>
              <Image src="images/annot1.jpg" thumbnail />
            </Col>
          </Row>
        </Container>
        <Container className="p-2">
          Leverages multiple search engines and inhouse filteration for higher
          volumes and accuracy. As the data-set attains a certain size, human
          participation in the data labeling keeps going down by leveraging
          techniques like Chaining and Cloning. SponPlus thus enables an
          uninterrupted supply of labeled data for continued training of models.
        </Container>
      </Stack>
    </Container>
  );
};

export default Annot;
