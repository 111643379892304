import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const About = () => {
  return (
    <Container className="my-5">
      <Row className="mb-4">
        <Col>
          <h1>About Us</h1>
          <p>
            With AI and Machine Learning showing the world the 'new possible',
            businesses now expect computing power and AI models to execute
            use-cases that were previously constrained. The team at Aavadhi AI
            has extensive industry experience of more than a combined 40 years.
            We realize that skills translated to products that answer market
            requirements is the best way to serve customers that expect nothing
            less than cutting edge technology to stay ahead of competition.
          </p>
          <p>
            The team at Aavadhi comes with skills to be able to answer the above
            business needs.
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Sandeep Chandrakeerthi</Card.Title>
              <Card.Text>
                More than 25 years of experience in the IT industry. Has worked
                for several leading global organizations like Oracle Financial
                Systems Software (earlier called i-flex solutions) and Nous
                Infosystems. He has extensive experience in Computer Vision,
                NLP, Big Data, etc. An Oklahoma City University and University
                of Alabama at Birmingham alumnus. He is Co-founder and CEO.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Devaraj Jagannath</Card.Title>
              <Card.Text>
                More than 10 years of engineering experience in the telecom, IoT
                and e-commerce verticals. Has proven capabilities in designing
                and implementing enterprises with cutting edge skills in
                Full-stack development, Big Data, Web Services, Mobile among
                other technologies while working for organizations like
                Infinera. An alumnus of UVCE, Bangalore University. He is
                Co-founder and CTO.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
