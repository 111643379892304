import React from "react";
import { Container, Image } from "react-bootstrap";

const ImageBar = ({ image, text, subtext }) => {
  if (!image) {
    return null;
  }

  return (
    <Container fluid className="image-bar-container">
      <div className="image-bar">
        <Image src={image} alt="Background Image" className="image-bar-image" />
        <div className="overlay-text">
          {text && <h1>{text}</h1>}
          {subtext && <p>{subtext}</p>}
        </div>
      </div>
    </Container>
  );
};

export default ImageBar;
