// src/components/HomePage.js
import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

import ImageBar from "../components/ImageBar";
import VideoBar from "../components/VideoBar";
import VideoEmbed from "../components/VideoEmbed";

const HomePage = () => {
  const partners = [
    { name: "Partner 1", logo: "aws-logo.png" },
    { name: "Partner 2", logo: "tf-logo.png" },
    { name: "Partner 3", logo: "new-keras-logo.png" },
  ];
  return (
    <>
      {/* <VideoBar /> */}
      <ImageBar
        image="images/home-img.png"
        text="Aavadhi AI"
        subtext="Clairvoyant Systems"
      />
      <Container className="text-center my-5">
        <Row>
          <Col>
            <h1 className="poppins-bold">Aavadhi AI</h1>
            <p className="poppins-light">
              Aavadhi is a Computer Vision and machine learing based solution
              <br />
              provider for Business, Government and Industry.
            </p>
          </Col>
        </Row>
        <Container className="d-flex justify-content-center">
          <Row className="justify-content-center w-100">
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-3 d-flex justify-content-center"
            >
              <Card
                style={{ width: "100%" }}
                className="h-100 d-flex flex-column"
              >
                <Card.Img
                  variant="top"
                  src="images/traffic-viol.jpg"
                  className="card-img"
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Traffic Enforcement</Card.Title>
                  <Card.Text>
                    Traffic Enforcement to help make our roads safer.
                    Streamlines compliance by integrating seamlessly with
                    upstream and downstream systems.
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="/traffic-enf"
                    className="mt-auto"
                  >
                    Know More
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-3 d-flex justify-content-center"
            >
              <Card
                style={{ width: "100%" }}
                className="h-100 d-flex flex-column"
              >
                <Card.Img
                  variant="top"
                  src="images/pub-space-mon.jpg"
                  className="card-img"
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Public Space Monitoring</Card.Title>
                  <Card.Text>
                    Aavadhi helps monitor public spaces like Railway and Metro
                    stations, Bus terminals and Airports. It can also be
                    leveraged to monitor public areas like Parks where there are
                    certain no-go areas for citizen safety.
                  </Card.Text>
                  <Button
                    variant="primary"
                    href="/space-monit"
                    className="mt-auto"
                  >
                    Know More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-3 d-flex justify-content-center"
            >
              <Card
                style={{ width: "100%" }}
                className="h-100 d-flex flex-column"
              >
                <Card.Img
                  variant="top"
                  src="images/retail2.png"
                  className="card-img"
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Retail</Card.Title>
                  <Card.Text>
                    Aavadhi in Retail helps monitor inventory and push Supply
                    Chain for optimal stocking in stores. This also helps in
                    making predictive analytics that will help companies make
                    data-driven business decisions!
                  </Card.Text>
                  <Button variant="primary" href="/retail" className="mt-auto">
                    Know More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-3 d-flex justify-content-center"
            >
              <Card
                style={{ width: "100%" }}
                className="h-100 d-flex flex-column"
              >
                <Card.Img
                  variant="top"
                  src="images/annot.jpg"
                  className="card-img"
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Annotations</Card.Title>
                  <Card.Text>
                    Aavadhi enables automation in both Data Gathering and Data
                    Annotation. Aavadhi leverages its in-house proprietary
                    technologies as well as commercially available technologies
                    for data-set gathering and data-labelling which the
                    customers can leverage to enjoy savings in time and effort
                    of up to 40-50%.
                  </Card.Text>
                  <Button variant="primary" href="/annot" className="mt-auto">
                    Know More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <Row className="mt-5">
        <Col>
          <img
            src="images/home-ai.jpg"
            alt="Home AI Theme Preview"
            className="img-fluid rounded shadow"
          />
        </Col>
      </Row> */}
        <VideoEmbed videoId="Rk-U1qpWbXY" header="Aavadhi AI capabilities" />

        <h2 className="section-header">Our Tech Partners</h2>
        <hr />
        {/* <Row className="justify-content-center mt-5">
          {partners.map((partner, index) => (
            <Col key={index} xs={6} sm={4} md={3} className="text-center">
              <img
                src={"images/" + partner.logo}
                alt={partner.name}
                className="partner-logo"
              />
            </Col>
          ))}
        </Row> */}
        <div class="partner-logos">
          {partners.map((partner, index) => (
            <img
              src={"images/" + partner.logo}
              alt={partner.name}
              className="partner-logo"
            />
          ))}
        </div>
      </Container>
    </>
  );
};

export default HomePage;
