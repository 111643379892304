// src/components/Retail.js
import React from "react";
import { Container, Row, Col, Stack, Image } from "react-bootstrap";
import ImageBar from "../components/ImageBar";
import VideoEmbed from "../components/VideoEmbed";

const Retail = () => {
  return (
    <>
      <ImageBar image="images/retail-bar.jpeg" />
      <Container className="text-center my-5">
        <h1 className="poppins-light">Aavadhi in Retail</h1>

        <Stack gap={3}>
          <Container className="fluid sm">
            Aavadhi in Retail helps monitor inventory and push Supply Chain for
            optimal stocking in stores. This also helps in making predictive
            analytics that will help companies make data-driven business
            decisions!
          </Container>
          <Container className="justify-content-center">
            <Row>
              {/* <Col>
                <Image src="images/retail2.png" thumbnail />
              </Col> */}
              <Col>
                <Image src="images/retail1.jpg" thumbnail />
              </Col>
            </Row>
          </Container>
          <Container className="p-2">
            As digital and physical purchasing channels blend together, the
            retailers that are able to innovate their retail channels will set
            themselves apart as market leaders.
          </Container>
        </Stack>
        <VideoEmbed
          videoId="xr-GFyaERBw"
          header="Aavadhi Retail Capabilities"
        />
      </Container>
    </>
  );
};

export default Retail;
