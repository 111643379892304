// src/components/SpaceMonit.js
import React from "react";
import { Container, Row, Col, Stack, Image } from "react-bootstrap";
import ImageBar from "../components/ImageBar";
import VideoEmbed from "../components/VideoEmbed";

const SpaceMonit = () => {
  return (
    <>
      <ImageBar image="images/public-space1.png" />
      <Container className="text-center my-5">
        <h1 className="poppins-light">Aavadhi in Space Monitoring</h1>

        <Stack gap={3}>
          <Container className="fluid sm">
            Aavadhi helps monitor public spaces like Railway and Metro stations,
            Bus terminals and Airports. It can also be leveraged to monitor
            public areas like Parks where there are certain no-go areas for
            citizen safety.
          </Container>
          <Container>
            <Row className="d-flex justify-content-center ">
              <Col xs={12} md={6} className="mb-3 d-flex flex-column">
                <Image src="images/space-monit1.jpg" thumbnail />
              </Col>
              <Col xs={12} md={6} className="mb-3 d-flex flex-column">
                <Image src="images/space-monit2.jpg" thumbnail />
              </Col>
            </Row>
          </Container>
          <Container className="p-2">
            Aavadhi plays a big part in safeguarding our public places and
            enable our citizens to enjoy the benefits of facilities built for
            our society knowing that the facilities are well monitored and
            protected!
          </Container>
        </Stack>
        <VideoEmbed
          videoId="nxEWOxmPL2E"
          header="Aavadhi Space Monitoring Capabilities"
        />
      </Container>
    </>
  );
};

export default SpaceMonit;
